import React from "react"

import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import TheorieIntensivkursTermine from "../../components/theorie-intensivkurs-termine/theorie-intensivkurs-termine"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"
import SolidHero from "../../components/solid-hero/solid-hero"

const KlasseB197Page = ({data}) => (
  <Layout>
    <SEO title="Klasse B197" description={'Du fürchtest, beim Schalten nicht so richtig in die Gänge zu kommen? Starte stressfrei: Mit dem B197er-Führerschein im Automatikwagen bei deiner Fahrschule Rauscher.'}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={'Klasse B197'} primary title={'Autoführerschein'} subtitle={() => <>Klasse B197</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn text={"Eine der erfreulichen Nachrichten aus 2021: Die Einführung der B197-Erweiterung. Und die hat, außer dem ähnlichen Namen, nichts mit ihrer 196er-Schwester gemein. Hier geht’s vor allem darum, deine Prüfung ordentlich zu entstressen. Bislang galt nämlich: Wer Fahrausbildung und -prüfung mit einem Automatikwagen absolviert, darf anschließend auch nur Automatikfahrzeuge starten. Die B197 setzt diese alte Regelung lässig außer Kraft! Denn wenn du dich für die 197er-Erweiterung entscheidest, darfst du deine reguläre Fahrprüfung im Automatikfahrzeug ablegen und anschließend trotzdem Schaltfahrzeuge fahren – es fallen lediglich ein paar Schaltstunden sowie ein 15-minütiger Test mit uns, bzw. deinem/deiner Fahrlehrer*in an. Für dich schnürt das gleich ein ganzes Paket an Vorteilen: Auf der einen Seite lassen sich die Schaltstunden in der Regel wunderbar in den regulären Unterricht integrieren und fallen nicht weiter auf. Auf der anderen Seite kannst du dich bei deiner praktischen Prüfung von Anfang an auf den Verkehr konzentrieren und voll in die Gänge kommen ohne dir über Selbiges Gedanken machen zu müssen. Hier sitzt du also wirklich am längeren Hebel!"}>
      <ClassOverviewItem title={'Ausbildung'}>
        <li>Ausbildung: Theorie und Praxis. Die Praxisausbildung findet zum großen Teil auf einem Automatikfahrzeug statt. Lediglich mindestens 10 Fahrstunden werden während der Ausbildung auf einem Schaltwagen absolviert.</li>
        <li>Prüfung: 1.) Theorieprüfung 2.) Praktische Prüfung (findet auf einem Automatikfahrzeug statt) 3.) Fahrschulinterner Test (findet auf einem Schaltfahrzeug statt; dies ist keine externe Prüfung; der Test wird direkt von deinem Fahrlehrer / deiner Fahrlehrerin durchgeführt; Dauer: mind. 15 Minuten)</li>
        <li>Bei Bestehen der Prüfungen darfst du weltweit Schaltwagen fahren (keine Beschränkung auf Automatikfahrzeuge vorhanden!).</li>
        <li>Eingeschlossene Klassen: AM, L</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Stundenpensum'}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 5</li>
        <li>Autobahnfahrt: 4</li>
        <li>Dunkelfahrt: 3</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 2</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Vorraussetzungen'}>
        <li>Mindestalter: 18 Jahre, 17 Jahre beim Begleiteten Fahren (BF17)</li>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Merkmale'}>
        <li>KFZ – AUSGENOMMEN KFZ DER KLASSEN AM, A1, A2 UND A</li>
        <li>... zG max. 3.500 kg</li>
        <li>... max. 8 Personen außer dem Fahrer</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Anhängerregelung'}>
        <li>... Anhänger mit zG max. 750 kg immer erlaubt</li>
        <li>... Anhänger mit zG über 750 kg erlaubt, wenn zG der Fahrzeugkombination max. 3.500 kg</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Weitere Infos'} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <TheorieIntensivkursTermine/>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseB197Page

export const query = graphql`
  query KlasseB197PageQuery {
    file(relativePath: { eq: "klasse-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
